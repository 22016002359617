import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { TaskDetails, TaskPriority, TaskStatus } from "../../../data/models/client";
import { useState } from "react";
import { format } from "date-fns";
import { useStore } from "../../../stores/store";
import ToDoForm from "../Form/ToDoForm";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface ClientToDoItemProps {
    todo: TaskDetails;
}

function ClientToDoItem({ todo }: ClientToDoItemProps) {
    const { t } = useTranslation();
    const { sidebarStore, clientStore, dialogStore } = useStore();
    const { openSidebar, closeSidebar } = sidebarStore;
    const { updateTaskStatus, deleteTask, getClientTasks, client, updateTaskState, getClientTask } = clientStore;
    const { openDialog, closeDialog } = dialogStore;
    const [status, setStatus] = useState(todo.state);

    const openForm = async (e: any) => {
        const isIconButtonClicked =
            e.target.tagName === 'BUTTON' ||
            e.target.parentNode?.tagName === 'BUTTON' ||
            e.target.parentNode?.parentNode?.tagName === 'BUTTON';

        if (isIconButtonClicked || e.target.type === "checkbox") return;

        const task = await getClientTask(todo.id);
        openSidebar(<ToDoForm todo={task} />, "Update task");
    }

    const handleChange = async (e: any) => {
        const oldStatus = status;
        const newStatus = e.target.checked ? TaskStatus.COMPLETE : TaskStatus.INCOMPLETE
        setStatus(newStatus);

        try {
            await updateTaskStatus(todo.id);
            todo.state = newStatus;
            updateTaskState(todo);
        } catch (error) {
            setStatus(oldStatus);
            updateTaskState(todo);
            throw error;
        }
    };

    const handleDelete = () => {
        openDialog(
            t("CLIENT_TASK_DEL"),
            t("CLIENT_TASK_DEL_CONF"),
            async () => {
                await deleteTask(todo!.id!);
                toast.success(t("TOAST_TASK_DELETE"));
                await getClientTasks(client?.id!);
                closeDialog();
                closeSidebar();
            },
            closeDialog)
    }

    const getStatus = () => {
        if (status === TaskStatus.COMPLETE) return true;
        else if (status === TaskStatus.INCOMPLETE) return false;

        return false;
    }

    return (
        <Box className="todo-item-container" onClick={openForm}>
            <Box className="todo-item-info">
                <Checkbox
                    checked={getStatus()}
                    onChange={handleChange}
                    icon={<CheckCircleOutlineIcon sx={{ color: "#ced4da" }} />}
                    checkedIcon={<CheckCircleOutlineIcon sx={{ color: "#5fa8d3" }} />}
                />
                <Typography className="todo-item-title">{todo.title}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                {todo.priority !== TaskPriority.MEDIUM &&
                    <ArrowRightAltOutlinedIcon
                        sx={{
                            color: `${todo.priority === TaskPriority.LOW ? '#0f0' : '#f00'}`,
                            transform: `rotate(${todo.priority === TaskPriority.LOW ? '90deg' : '-90deg'})`
                        }} />}
                <Typography className="todo-item-date">{format(dayjs(todo.dueDate).toDate(), 'MMM d')}</Typography>
                <IconButton
                    onClick={handleDelete}
                    title="Delete"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#f443361c'
                        },
                        marginLeft: '6px'
                    }}
                    TouchRippleProps={{
                        style: {
                            color: '#f443369d'
                        }
                    }}
                >
                    <DeleteForeverOutlinedIcon sx={{ color: '#f44336' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default observer(ClientToDoItem);