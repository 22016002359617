import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { format } from "date-fns";

import ActivityLayout from "./ActivityLayout";
import { useStore } from "../../../stores/store";
import { useEffect, useState } from "react";
import { CalendarEvent } from "../../../data/models/event";
import ClientEvents from "./ClientEvents";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ClientDetailSessionTable from "../Form/ClientDetailSessionTable";
import { ClientDetailSessionsDto } from "../../../data/models/client";
import { observer } from "mobx-react-lite";
import { SessionFile } from "../../../data/models/session";

function ClientAbout() {
    const { t } = useTranslation();
    const { clientStore, bookingStore, institutionStore } = useStore();
    const { client, getSessionsOnClientDetail } = clientStore;
    const { getClientEvents } = bookingStore;
    const { selectedUserInstitution } = institutionStore
    const navigate = useNavigate();
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [sessions, setSessions] = useState<ClientDetailSessionsDto[]>([]);
    const [ICFiles, setICFiles] = useState<SessionFile[]>([]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (client) {
                const events = await getClientEvents(client!.id!);
                setEvents(events!);
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)
                setSessions(clientSessions);
            }
        };

        fetchEvents();
    }, [client, getClientEvents]);

    const handleSessionUpdate = async () => {
        setTimeout(async () => {
            if (client) {
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)
                setSessions(clientSessions);
            }
        }, 400);
    };

    return (
        <Box className="about-container">
            <ActivityLayout
                showFlag={false}
                className="general-information-container"
            >
                <Box className="general-information">
                    <Box className="general-information-header">
                        <Typography fontSize="22px" fontWeight="700">
                            {t("CLIENT_GENERAL_INFO")}
                        </Typography>
                        {
                            client !== null && !selectedUserInstitution?.roles.some(r => ["HELPER", "CLIENT"].includes(r.toUpperCase())) ?
                                <IconButton onClick={() => navigate(`/users/${client!.id}/update`)}>
                                    <EditIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                : ''
                        }
                    </Box>
                    <Box className="client-personal-info">
                        <Box className="about-information">
                            <Typography fontSize="16px" fontWeight={600}>
                                {t("USER_GENDER")}
                            </Typography>
                            <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                {client?.gender ?? "No information."}
                            </Typography>
                        </Box>
                        <Box className="about-information">
                            <Typography fontSize="16px" fontWeight={600}>
                                {t("USER_BIRTHDATE")}
                            </Typography>
                            <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                {client?.birthDate ?
                                    format(new Date(client.birthDate), "dd MMMM, yyyy") :
                                    "No information."}
                            </Typography>
                        </Box>
                        <Box className="about-information">
                            <Typography fontSize="16px" fontWeight={600}>
                                {t("CLIENT_LOCATIONS")}
                            </Typography>
                            <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                {client?.fullAddress ?? "No information."}
                            </Typography>
                        </Box>
                        <Box className="about-information">
                            <Typography fontSize="16px" fontWeight={600}>
                                {t("CLIENT_ABOUT")}
                            </Typography>
                            <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                {client?.notes ?? t("CLIENT_NO_INFO")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </ActivityLayout>
            <Box className="session-container">
                <Box className="session-header">
                    <Typography fontSize="22px" fontWeight={500}>{t("SESSIONS_LABEL")}</Typography>
                </Box>
                <ClientDetailSessionTable sessions={sessions} updateSession={handleSessionUpdate} />
            </Box>
            <Box className="schedule-container">
                <ClientEvents events={events} clientId={client?.id!} />
                <Box className="helper-list-container">
                    <Typography fontSize="18px" fontWeight={600}>
                        {t("USER_RESPONSIBLE_PEOPLE")}
                    </Typography>
                    <Box className="helper-list">
                        {client?.responsibleUsers && client!.responsibleUsers!.length > 0 ? (
                            client!.responsibleUsers.map((element) => (
                                <Box key={element.responsibleUserId}>
                                    <Typography>{element.responsibleUserName}</Typography>
                                </Box>
                            ))
                        ) : (
                            <Typography fontSize={"14px"}>
                                {t("CLIENT_NO_RESPONSIBLE_PEOPLE")}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default observer(ClientAbout);
