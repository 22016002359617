import {
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Typography,
} from "@mui/material";
import { RoundButton } from "../../_styles/StyledButtons";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function DataExportDashboard() {
    const { t } = useTranslation();
    const {
        dataExportStore: { getDataExport, loading },
        commonStore: { selectedInstitution },
    } = useStore();

    const exportData = async () => {
        if (!selectedInstitution) return;
        try {
            const data = await getDataExport(selectedInstitution);

            const blob = new Blob([data], { type: "text/csv" });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `result.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    return (
        <Container
            sx={{ display: "flex", justifyContent: "center", mt: "8rem" }}
        >
            <Card sx={{ minWidth: 350, maxWidth: 350, bgcolor: "#fff", p: "2rem" }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 28, fontWeight: 600, textAlign: "center" }}
                        color="text.secondary"
                        gutterBottom
                    >
                        {t("DE_RESULTS")}
                    </Typography>
                </CardContent>
                <CardActions>
                    <RoundButton
                        size="small"
                        variant="contained"
                        color="success"
                        sx={{ width: "100%", color: "#fff" }}
                        onClick={exportData}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={25} /> : t("DE_EXPORT")}
                    </RoundButton>
                </CardActions>
            </Card>
        </Container>
    );
}

export default observer(DataExportDashboard);
