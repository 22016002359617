import { Dayjs } from "dayjs";
import { Recurrence } from "./event";
import { ResponsiblePersonDto, UserInstitutions } from "./user";
import { Pagination } from "./pagination";
import { FollowUpType, SessionStatus } from "./session";

export interface Client {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    hasAccount: string;
    roles: ClientRole[];
}

export interface ProcessClient {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}


export interface ClientAboutDto {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    gender?: string;
    birthDate?: Date;
    fullAddress: string;
    notes?: string;
    ahvNumber: string;
    responsibleUsers: ResponsiblePersonDto[];
    healthInsurance?: string;
    originCountry?: string;
}

export interface ClientDetailsDto {
    contactWithReferringAgency: boolean;
    helperGenderPreference: string;
    needsChildCare: boolean;
    contactAgency?: ContactAgency;
}

export interface ContactAgency {
    agencyName?: string;
    agencyEmail?: string;
    agencyPhone?: string;
}

export interface ClientRole {
    id: number,
    name: string
}

export interface ClientQueryParams {
    roleId?: number;
    paging?: Pagination;
    search?: string;
    sortField?: string;
    sortOrder?: string;
}

export interface ClientBasicInfoDto {
    firstName: string;
    lastName: string;
    gender: string;
    birthdate: Date;
    phoneNumber: string;
    country?: string;
    city?: string;
    street?: string;
    number: string;
    zip?: string;
    email?: string;
    hasAccount: boolean;
    password?: string;
    platformLanguageAlpha2?: string;
    userLanguages: string[];
    responsiblePerson?: string;
    notes?: string;
    residenceStatus?: string;
    institutionsAssigned?: UserInstitutions[];
    tenantId: string;
    ahvNumber: string | null;
    zemisNumber: string | null;
    accomodation: string | null;
    healthInsurance: string | null;
    originCountry: string;
}

export interface ClientUpdateDto {
    firstName: string;
    lastName: string;
    gender: string;
    birthdate: Date;
    phoneNumber: string;
    country?: string;
    city?: string;
    street?: string;
    number: string;
    zip?: string;
    email?: string;
    platformLanguageAlpha2?: string;
    userLanguages: string[];
    residenceStatus?: string;
    institutionsAssigned?: UserInstitutions[];
    originCountry: string;
}

export interface ClientEvent {
    startDate: Date;
    endDate: Date;
    startTime: Dayjs;
    endTime: Dayjs;
    frequency: number;
    isAllDay: boolean;
    isScreening: boolean;
    recurrence: Recurrence;
}

export interface ClientDetailsCreateDto {
    id: string;
    helperGenderPreference: string;
    needsChildCare: boolean;
    contactWithReferringAgency: boolean;
    contactAgency?: ContactAgency;
}

export interface ClientDetailSessionsDto {
    id: string;
    status: SessionStatus;
    assessorFullName: string;
    institutionId: string;
    timeline: string,
    study: string,
    isTriageConsentCompleted: boolean
    triageAccepted: boolean,
    followupType: FollowUpType
}

export interface Timeline {
    id: string;
    title: string;
    type: string;
    time: Date;
    fieldsChanged: AffectedFieldsObject[];
    eventDescription?: string;
    institutionName?: string;
    responseId?: string;
}

export interface AffectedFieldsObject {
    [key: string]: FieldData;
}

export interface FieldData {
    oldValue: string | null;
    newValue: string | null;
}

export interface Task {
    title: string,
    priority: TaskPriority,
    dueDate: Date,
    notes: string,
}

export interface TaskDetails extends Task {
    dayjs: any,
    id: string,
    reminder: string,
    state: TaskStatus,
    createdBy: string,
    files: CloudFile[],
    completedBy: UserTaskComplete;
    completedAt: Date;
}

export interface UserTaskComplete {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface TaskUpdateDto extends Task {
    id: string,
    existingFiles: string[],
}

export enum TaskStatus {
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
}

export enum TaskPriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export interface CloudFile {
    id: string,
    fileName: string,
    size: number,
    url: string,
    isDeleted: boolean,
}

export interface TaskCreateDto extends Task {
    userId: string,
}

export enum TaskFilterType {
    STATUS = 'STATUS',
    PRIORITY = 'PRIORITY',
}

export interface TaskFilter {
    type: TaskFilterType,
    value: string | boolean
}