
import { Box, Fab, Typography } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { format } from "date-fns";

import { CalendarEvent } from "../../../data/models/event";
import EventDetails from "./EventDetails";
import { Views } from "react-big-calendar";

interface EventListProps {
    events: CalendarEvent[];
    open: boolean;
    date: Date;
    handleOpen: (open: boolean) => void;
    handleSelect: (event: CalendarEvent, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    view: string;
}

const hiddenStyle = {
    display: 'none'
}

const listStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '10px',
    width: '25%',
    height: '76vh',
    overflow: 'hidden',
}

const headerStyle = {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    borderBottom: "1px solid #ddd",
}

const fabStyle = {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    height: '35px',
    width: '35px',
};

function EventList({ events, open, handleOpen, date, handleSelect, view }: EventListProps) {
    return (
        <>
            <Box sx={open ? listStyle : hiddenStyle} className="event-details">
                <Box height="100%" width="100%" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <Box sx={headerStyle}>
                        <Typography variant="body1" component="h5" padding="10px">{format(date, 'EEE, MMM d')}</Typography>
                    </Box>
                    {events.map((event, index) => (
                        <EventDetails key={index} event={event} handleSelect={handleSelect} />
                    ))}
                </Box>
                <Fab
                    color="primary"
                    title="Event list"
                    sx={{ ...fabStyle, position: 'relative', right: 0, bottom: 0, alignSelf: 'flex-start', margin: '10px' }}
                    onClick={() => handleOpen(false)}
                >
                    <KeyboardBackspaceOutlinedIcon fontSize="small" sx={{ transform: open ? 'rotateY(180deg)' : 'inherit' }} />
                </Fab>
            </Box>
            {!open && view === Views.MONTH && <Fab
                color="primary"
                title="Event list"
                data-testid="event-list-button"
                sx={fabStyle}
                onClick={() => handleOpen(true)}
            >
                <KeyboardBackspaceOutlinedIcon fontSize="small" sx={{ transform: open ? 'rotateY(180deg)' : 'inherit' }} />
            </Fab>}
        </>
    );
}

export default EventList;