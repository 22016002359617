import { Chip, Typography, lighten } from "@mui/material";
import FactCheckRoundedIcon from "@mui/icons-material/FactCheckRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import LockPersonRoundedIcon from "@mui/icons-material/LockPersonRounded";
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import ReportIcon from '@mui/icons-material/Report';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';

import { TFunction } from "i18next";

import { SessionStatus, SessionSurveyStatus } from "../../../../data/models/session";

export function getSurveyStatusChip(status: SessionSurveyStatus, t: TFunction<"translation", undefined>): JSX.Element {
    const { color } = getSurveyStatusProps(status);

    return (
        <Chip
            label={<Typography fontSize={11} fontWeight={500}>{t(`SESSION_${status}`)}</Typography>}
            sx={{ bgcolor: color, height: "26px" }}
        />
    );
}

export function getSurveyStatusProps(status: SessionSurveyStatus): { color: string } {
    switch (status) {
        case SessionSurveyStatus.NOTSTARTED:
            return { color: lighten("#748cab", 0.8) };
        case SessionSurveyStatus.INPROGRESS:
            return { color: lighten("#0077b6", 0.8) };
        case SessionSurveyStatus.COMPLETED:
            return { color: lighten("#2a9d8f", 0.8) };

        default:
            return { color: "#748cab" };
    }
}


export function getSessionStatusChip(status: SessionStatus, t: TFunction<"translation", undefined>): JSX.Element {
    const { icon, color } = getStatusProps(status);
    return (
        <Chip
            icon={icon}
            label={<Typography fontSize={11} fontWeight={500}>{t(`SESSION_${status}`)}</Typography>}
            sx={{ bgcolor: color, height: "26px" }}
        />
    );
};

export function getStatusProps(status: SessionStatus): { icon: JSX.Element, color: string } {
    switch (status) {
        case SessionStatus.ACTIVE:
            return {
                icon: <GraphicEqRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#2a9d8f !important" }} />,
                color: lighten("#2a9d8f", 0.8)
            };
        case SessionStatus.BLONLY:
            return {
                icon: <FactCheckRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#588157 !important" }} />,
                color: lighten("#588157", 0.8)
            };
        case SessionStatus.EXCLUDED_SUICIDALITY:
            return {
                icon: <FactCheckRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#588157 !important" }} />,
                color: lighten("#588157", 0.8)
            };
        case SessionStatus.CANCELLED:
            return {
                icon: <CancelRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#0f4c5c !important" }} />,
                color: lighten("#0f4c5c", 0.8)
            };
        case SessionStatus.AWAITING_EVENT_CREATION:
            return {
                icon: <FactCheckIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#8338ec !important" }} />,
                color: lighten("#8338ec", 0.8)
            }
        case SessionStatus.CLIENT_NO_SHOW_UP:
            return {
                icon: <PersonOffRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#354f52 !important" }} />,
                color: lighten("#354f52", 0.8)
            }
        case SessionStatus.EXCLUDED:
            return {
                icon: <LockPersonRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#fca311 !important" }} />,
                color: lighten("#fca311", 0.8)
            };
        case SessionStatus.ABORT:
            return {
                icon: <DoDisturbOnRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#e63946 !important" }} />,
                color: lighten("#e63946", 0.8)
            };
        case SessionStatus.COMPLETED:
            return {
                icon: <LibraryAddCheckRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#0077b6 !important" }} />,
                color: lighten("#0077b6", 0.8)
            };
        case SessionStatus.EMERGENCY_HOLD:
            return {
                icon: <ReportIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#dbd600 !important" }} />,
                color: lighten("#dbd600", 0.8)
            };
        case SessionStatus.DEACTIVATED:
            return {
                icon: <MotionPhotosOffIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#B3B3B3 !important" }} />,
                color: lighten("#B3B3B3", 0.8)
            };
        default:
            return {
                icon: <></>, color: "#fff"
            };
    }
};