import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    Skeleton,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { format } from "date-fns";

import { useStore } from "../../../../../stores/store";
import {
    ClientAboutDto,
    ClientDetailsDto,
} from "../../../../../data/models/client";
import "../styles.css";
import { capitalizeFirstLetter } from "../../../../../utils/utils";
import { useParams } from "react-router-dom";
import { SessionFile, SessionStatus } from "../../../../../data/models/session";
import CloudFileInfo from "../../../../../components/CloudFileInfo";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import SessionFileForm from "../Forms/SessionFileForm";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import AhvEditForm from "./AhvEditForm";
import HealthInsuranceEditForm from "./HealthInsuranceEditForm";
import ClientInfoEditForm from "./ClientInfoEditForm";

interface InfoSectionProps {
    label: string | React.ReactNode;
    value: string | React.ReactNode;
}

interface ClientDataProps {
    about: ClientAboutDto;
    details: ClientDetailsDto;
    sessionStatus?: string;
}

interface ClientConsentProps {
    active: boolean;
}

export const ClientConsent = observer(function ClientConsent({ active }: ClientConsentProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore, dialogStore, authStore, loadingStore } = useStore();
    const { getConsentFiles, session, setConsentionAgreement, loadAssessorSession, setTriageConsent } =
        sessionStore;
    const { openModal } = modalStore;
    const { user } = authStore;
    const { isLoading } = loadingStore;
    const { openDialog, closeDialog } = dialogStore;
    const [ICFiles, setICFiles] = useState<SessionFile[]>([]);

    const { sessionId } = useParams();

    const rejectedState = [
        "CONSENT_REJECTED",
        "2ND_CONSENT_REJECTED",
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (sessionId && session?.assessorId === user?.id) {
                const uploadedFiles = await getConsentFiles(sessionId);
                setICFiles(uploadedFiles);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getConsentFiles, sessionId, session]);

    const getICFiles = (order: number) => {
        return ICFiles.filter((f) => f.order === order);
    };

    const onConsentReject = () => {
        let message = t("SESSION_IC_REJECT");
        if (session?.currentStep === "AWAITING_2ND_CONSENT_AGREEMENT") {
            message += ` ${t("SESSION_IC_BL_REJECT")}`;
        }
        message += ".";

        openDialog(
            t("SESSION_IC_REJECT_TITLE"),
            message,
            async () => {
                if (sessionId) {
                    await setConsentionAgreement(sessionId, false);
                    await loadAssessorSession(sessionId);
                    closeDialog();
                }
            },
            closeDialog,
            { text: t("GENERAL_CONFIRM"), color: "error" }
        );
    };

    const onTriageReject = () => {
        let title = t("TRIAGE_CONSENT_DIALOG_REJECT_TITLE")
        let message = t("TRIAGE_CONSENT_DIALOG_REJECT_MESSAGE")

        openDialog(
            title,
            message,
            async () => {
                if (sessionId) {
                    await setTriageConsent(sessionId, false);
                    await loadAssessorSession(sessionId);
                    closeDialog();
                }
            },
            closeDialog,
            { text: t("GENERAL_CONFIRM"), color: "error" }
        );
    };

    if (session?.assessorId !== user?.id)
        return (
            <Box>
                <Typography textAlign={"center"} fontSize={"0.8rem"}>
                    {t("SESSION_IC_NO_PERM")}
                </Typography>
            </Box>
        );

    return (
        <Box
            sx={{
                borderBottom: "none",
                pb: 1,
                mb: "4px",
                display: "flex",
                justifyContent: "space-between",
                gap: 2
            }}

        >
            <Box sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                flexGrow: 1,
                padding: 2
            }}>
                <CloudFileInfo
                    files={ICFiles.filter((f) => f.order === 0)}
                    labels={{
                        header: t("SESSION_FIRST_IC"),
                        emptyText: t("SESSION_NO_FILES"),
                    }}
                    canDelete={false}
                />
                {active && session?.currentStep === "SESSION_LOADED" && (
                    <Box sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            flexGrow: 1,
                            padding: 4
                        
                    }}>
                        <RoundButton
                            sx={{ width: "50%" }}
                            color="error"
                            disableTouchRipple
                            onClick={onConsentReject}
                            disabled={isLoading(setConsentionAgreement) ||
                                session.status === SessionStatus.DEACTIVATED ||
                                session.status === SessionStatus.EMERGENCY_HOLD}
                        >
                            {t("IC_REJECTED_LABEL")}
                        </RoundButton>
                        <RoundButton
                            sx={{ width: "50%" }}
                            color="info"
                            disableTouchRipple
                            disabled={isLoading(setConsentionAgreement) ||
                                session.status === SessionStatus.DEACTIVATED ||
                                session.status === SessionStatus.EMERGENCY_HOLD
                            }
                            onClick={async () => {
                                if (sessionId) {
                                    await setConsentionAgreement(sessionId, true);
                                    await loadAssessorSession(sessionId);
                                }
                            }}
                        >
                            {isLoading(setConsentionAgreement) ? (
                                <CircularProgress color="secondary" size={25} />
                            ) : (
                                t("IC_ACCEPT_LABEL")
                            )}
                        </RoundButton>
                    </Box>
                )}
                <Box display={"flex"} justifyContent={"space-evenly"} mt={"4px"}>
                    {session?.status !== SessionStatus.DEACTIVATED &&
                        session?.status !== SessionStatus.EMERGENCY_HOLD &&
                        ![
                            "CONSENT_REJECTED",
                            "SESSION_LOADED",
                            "CLIENT_NO_SHOW_UP",
                        ].includes(session?.currentStep ?? "") &&
                        (
                            <RoundButton
                                onClick={() =>
                                    openModal(
                                        <SessionFileForm order={0} uploadedFiles={getICFiles(0)} />,
                                        { size: "sm" }
                                    )
                                }
                                sx={{ width: "auto" }}
                                disableTouchRipple
                            >
                                <FileUploadOutlinedIcon sx={{ fontSize: "18px", mr: "4px" }} />
                                {t("IC_UPLOAD_FILE")}
                            </RoundButton>
                        )}
                </Box>
            </Box>
            <Box sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                flexGrow: 1,
                padding: 2
            }}>
                <CloudFileInfo
                    files={ICFiles.filter((f) => f.order === 1)}
                    labels={{
                        header: t("SESSION_SECOND_IC"),
                        emptyText: `${t("SESSION_NO_FILES")}`,
                    }}
                    canDelete={false}
                />
                {session?.currentStep === "AWAITING_2ND_CONSENT_AGREEMENT" &&
                    session.status !== "BL_ONLY" &&
                    session.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                    session.isImpairmentCompleted &&
                    session.isImpairmentFormCompleted &&
                    (!session.mustCompleteAssessmentOfSuicide ||
                        (session.mustCompleteAssessmentOfSuicide &&
                            session.isAssessmentOfSuicideCompleted &&
                            session.isAssessmentOfSuicideFormCompleted))
                    && (
                        <Box>
                            <RoundButton
                                sx={{ width: "50%" }}
                                color="error"
                                disableTouchRipple
                                onClick={onConsentReject}
                                disabled={isLoading(setConsentionAgreement)}
                            >
                                {t("IC_REJECTED_LABEL")}
                            </RoundButton>
                            <RoundButton
                                sx={{ width: "50%" }}
                                color="info"
                                disableTouchRipple
                                disabled={isLoading(setConsentionAgreement)}
                                onClick={async () => {
                                    if (sessionId) {
                                        await setConsentionAgreement(sessionId, true);
                                        await loadAssessorSession(sessionId);
                                    }
                                }}
                            >
                                {isLoading(setConsentionAgreement) ? (
                                    <CircularProgress color="secondary" size={25} />
                                ) : (
                                    t("IC_ACCEPT_LABEL")
                                )}
                            </RoundButton>
                        </Box>
                    )}
                <Box display={"flex"} justifyContent={"space-evenly"} mt={"4px"}>
                    {!rejectedState.includes(session?.currentStep ?? "") &&
                        session?.status !== SessionStatus.DEACTIVATED &&
                        session?.status !== SessionStatus.EMERGENCY_HOLD &&
                        session?.status !== "BL_ONLY" &&
                        session?.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
                        session?.timeline !== "Baseline" && (
                            <RoundButton
                                onClick={() =>
                                    openModal(
                                        <SessionFileForm order={1} uploadedFiles={getICFiles(1)} />,
                                        { size: "sm" }
                                    )
                                }
                                sx={{ width: "auto" }}
                                disableTouchRipple
                            >
                                <FileUploadOutlinedIcon sx={{ fontSize: "18px", mr: "4px" }} />
                                {t("IC_UPLOAD_FILE")}
                            </RoundButton>
                        )}
                </Box>
            </Box>

            <Box sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                flexGrow: 1,
                padding: 2,

            }}>
                <Typography
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ marginRight: "10px" }}
                >
                    Triage Consent
                </Typography>
                {session?.isTriageConsentCompleted ? (<Typography
                    fontSize="13px"
                    sx={{ color: "#a2a2a2", marginLeft: "1rem" }}
                >
                    Filled out
                </Typography>) : (
                    session?.status === SessionStatus.ACTIVE && session.timeline === "Baseline" && session?.isTriageCompleted && <Box>
                        <RoundButton
                            sx={{ width: "50%" }}
                            color="error"
                            disableTouchRipple
                            onClick={onTriageReject}
                            disabled={isLoading(setTriageConsent)}
                        >
                            {t("IC_REJECTED_LABEL")}
                        </RoundButton>
                        <RoundButton
                            sx={{ width: "50%" }}
                            color="info"
                            disableTouchRipple
                            disabled={isLoading(setTriageConsent)}
                            onClick={async () => {
                                if (sessionId) {
                                    await setTriageConsent(sessionId, true);
                                    await loadAssessorSession(sessionId);
                                }
                            }}
                        >
                            {isLoading(setTriageConsent) ? (
                                <CircularProgress color="secondary" size={25} />
                            ) : (
                                t("IC_ACCEPT_LABEL")
                            )}
                        </RoundButton>
                    </Box>)
                }
            </Box>
        </Box >
    );
});

function InfoSection({ label, value }: InfoSectionProps) {
    return (
        <Box
            sx={{
                borderBottom: "none",
                pb: 1,
                mb: "4px",
            }}
        >
            {typeof value === "string" ? (
                <Typography fontSize={14} fontWeight={500} gutterBottom>
                    {value}
                </Typography>
            ) : (
                value
            )}
            {typeof label === "string" ? (
                <Typography fontSize={11} color="text.secondary">
                    {label}
                </Typography>
            ) : (
                label
            )}
        </Box>
    );
}

function ClientAddress({ about, details }: ClientDataProps) {
    const { t } = useTranslation();
    const NoDataLabel = (
        <Typography fontSize={12} sx={{ fontStyle: "italic", color: "#808080" }}>
            {t("GENERAL_NO_DATA")}
        </Typography>
    );

    return (
        <>
            <Typography
                fontSize={16}
                fontWeight={500}
                sx={{ mb: 1, color: "#52b2ff" }}
            >
                {t("CLIENT_ADDRESS")}
            </Typography>
            <InfoSection
                label={t("GENERAL_COUNTRY")}
                value={about?.originCountry ?? NoDataLabel}
            />
            <Typography
                fontSize={16}
                fontWeight={500}
                sx={{ mb: 1, color: "#52b2ff" }}
            >
                {t("GENERAL_CURRENT_ADDR")}
            </Typography>
            <InfoSection
                label={t("GENERAL_FULL_ADDR")}
                value={about?.fullAddress ?? NoDataLabel}
            />
        </>
    );
}

function ClientInfo({ about, details }: ClientDataProps) {
    const [insuranceEdit, setInsuranceEdit] = useState(false);
    const { t } = useTranslation();
    const { clientStore } = useStore();
    const { updateAgencyName, updateAgencyPhoneNumber, updateAgencyEmail, fieldLoading } = clientStore;
    const [clientDetails, setClientDetails] = useState(details);

    const handleDetailsUpdate = (updatedDetails: any) => {
        setClientDetails(updatedDetails);
    };

    useEffect(() => {
        setClientDetails(details);
    }, [details]);

    const getGender = (gender: string) => {
        return gender === "male"
            ? t("GENERAL_MALE")
            : gender === "female"
                ? t("GENERAL_FEMALE")
                : t("CLIENT_HELPER_UNSP");
    };

    return (
        <Box sx={{ overflowY: "auto" }}>
            <Box>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                    sx={{ mb: 1, color: "#52b2ff" }}
                >
                    {t("ACC_PI")}
                </Typography>
            </Box>
            <InfoSection label="Gender" value={getGender(about?.gender ?? "")} />
            <InfoSection
                label={t("USER_BIRTHDATE")}
                value={
                    about?.birthDate
                        ? format(new Date(about.birthDate), "dd MMMM, yyyy")
                        : t("CLIENT_NO_INFO")
                }
            />
            <InfoSection
                label={t("CLIENT_AHV")}
                value={about?.ahvNumber ?? <AhvEditForm clientId={about?.id} />}
            />
            <InfoSection
                label={t("CLIENT_HEALTH_INSURANCE")}
                value={about && <HealthInsuranceEditForm clientId={about?.id} value={about?.healthInsurance} />}
            />
            <Typography
                fontSize={16}
                fontWeight={500}
                sx={{ mb: 1, color: "#52b2ff" }}
            >
                {t("CLIENT_FAS")}
            </Typography>
            <InfoSection
                label={t("CLIENT_CHILDCARE")}
                value={details?.needsChildCare ? t("GENERAL_YES") : t("GENERAL_NO")}
            />
            <Typography
                fontSize={16}
                fontWeight={500}
                sx={{ mb: 1, color: "#52b2ff" }}
            >
                {t("CLIENT_PREF")}
            </Typography>
            <InfoSection
                label={t("CLIENT_HELPER_PREF")}
                value={
                    details?.helperGenderPreference
                        ? capitalizeFirstLetter(details.helperGenderPreference)
                        : t("CLIENT_HELPER_UNSP")
                }
            />
            <InfoSection
                label={t("CLIENT_AGENCY")}
                value={
                    details?.contactWithReferringAgency
                        ? t("GENERAL_YES")
                        : t("GENERAL_NO")
                }
            />
            <InfoSection
                label={t("CLIENT_AGENCY_NAME")}
                value={<ClientInfoEditForm
                    clientId={about?.id}
                    fieldName="agencyName"
                    fieldValue={clientDetails?.contactAgency?.agencyName}
                    updateField={updateAgencyName}
                    isLoading={fieldLoading}
                    label="Agency Name"
                    onDetailsUpdate={handleDetailsUpdate}
                />}
            />
            <InfoSection
                label={t("CLIENT_AGENCY_PHONE")}
                value={<ClientInfoEditForm
                    clientId={about?.id}
                    fieldName="phoneNumber"
                    fieldValue={clientDetails?.contactAgency?.agencyPhone}
                    updateField={updateAgencyPhoneNumber}
                    isLoading={fieldLoading}
                    label="Phone Number"
                    onDetailsUpdate={handleDetailsUpdate}
                />}
            />
            <InfoSection
                label={t("CLIENT_AGENCY_EMAIL")}
                value={<ClientInfoEditForm
                    clientId={about?.id}
                    fieldName="agencyEmail"
                    fieldValue={clientDetails?.contactAgency?.agencyEmail}
                    updateField={updateAgencyEmail}
                    isLoading={fieldLoading}
                    label="Email"
                    onDetailsUpdate={handleDetailsUpdate}
                />}
            />
            <ClientAddress about={about} details={details} />
        </Box>
    );
}

function SessionClientInfo() {
    const { clientStore, institutionStore, sessionStore, authStore } = useStore();
    const { getClientDetails, getClient, client } = clientStore;
    const { selectedUserInstitution } = institutionStore;
    const { session } = sessionStore;
    const { user } = authStore;
    const [width, setWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    window.addEventListener('resize', function (event) {
        setWindowWidth(window.innerWidth)
    });
    const [clientDetails, setClientDetails] = useState<ClientDetailsDto | null>(
        null
    );
    // const [tab, setTab] = useState<number>(0);
    const [showToggle, setShowTogge] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [hasError, setHasError] = useState(false);

    useLayoutEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.clientWidth);
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const clientId = searchParams.get("clientId");
        const fetchData = async () => {
            try {
                if (selectedUserInstitution) {
                    const clientDetails = await getClientDetails(
                        clientId!,
                        selectedUserInstitution?.institutionId!
                    );
                    await getClient(clientId!, selectedUserInstitution.institutionId);
                    setClientDetails(clientDetails);
                    setHasError(false);
                }
            } catch (error) {
                setHasError(true);
                console.log(error);
            }
        };

        fetchData();
    }, [getClient, getClientDetails, selectedUserInstitution]);

    /*const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const showConsentHighlight =
        session?.status !== SessionStatus.EMERGENCY_HOLD &&
        session?.status !== SessionStatus.DEACTIVATED &&
        session?.status !== SessionStatus.BLONLY &&
        session?.status !== SessionStatus.EXCLUDED_SUICIDALITY &&
        ["SESSION_LOADED", "AWAITING_2ND_CONSENT_AGREEMENT"].includes(
            session?.currentStep ?? ""
        ) &&
        user?.id === session?.assessorId &&
        (session?.currentStep !== "AWAITING_2ND_CONSENT_AGREEMENT" || session?.isImpairmentFormCompleted) &&
        (!session?.mustCompleteAssessmentOfSuicide || session?.isAssessmentOfSuicideFormCompleted) &&
        tab !== 1;*/

    const isSameOrPastDate = (date: Date) => {
        const currentDate = new Date();
        return (
            date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getDate() === currentDate.getDate()
        ) || date < currentDate;
    };

    const isActive = !!session?.timelineStartTime && isSameOrPastDate(new Date(session.timelineStartTime));


    if (hasError) return <></>;

    return (
        <Box
            className={`client-info-container ${toggle ? "client-hidden" : ""}`}
            ref={containerRef}
            onMouseEnter={() => setShowTogge(true)}
            onMouseLeave={() => setShowTogge(false)}
        >
            <Box className="settings-toggle-container">
                <IconButton
                    disableRipple
                    className="settings-toggle-button"
                    sx={{
                        display: showToggle || toggle ? "-webkit-inline-flex" : "none",
                    }}
                    onClick={() => setToggle(!toggle)}
                >
                    {toggle ? (
                        <ChevronRightRoundedIcon sx={{ fontSize: "18px" }} />
                    ) : (
                        <ChevronLeftRoundedIcon sx={{ fontSize: "18px" }} />
                    )}
                    {toggle && (
                        <Skeleton
                            className="button-wave-highlight"
                            sx={{ height: "24px !important", borderRadius: "50%" }}
                            animation="pulse"
                            variant="rounded"
                        />
                    )}
                </IconButton>
            </Box>
            <Card
                className="user-card"
                sx={{ position: "relative", zIndex: 2, overflowY: "auto" }}
            >
                <CardContent
                    sx={{
                        position: "absolute",
                        width: "-webkit-fill-available",
                        minWidth: width - 32,
                        zIndex: 0,
                    }}
                >
                    <CardHeader
                        sx={{ p: 0 }}
                        avatar={
                            <Avatar
                                sx={{
                                    bgcolor: "#52b2ff",
                                    height: "74px",
                                    width: "74px",
                                    fontSize: "34px",
                                }}
                            >
                                {client?.firstName.charAt(0).toLocaleUpperCase()}
                                {client?.lastName.charAt(0).toLocaleUpperCase()}
                            </Avatar>
                        }
                        title={
                            <Box>
                                <Typography fontSize={16}>
                                    {client?.firstName} {client?.lastName}
                                </Typography>
                                <Typography fontSize={11} color="text.secondary">
                                    {client?.email}
                                </Typography>
                            </Box>
                        }
                    />
                    <Box mb={2} mt={1}>
                        <ClientInfo about={client!} details={clientDetails!} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default observer(SessionClientInfo);
