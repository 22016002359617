import { Box, Container, IconButton, InputBase, Paper, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Sidebar from "../../../components/sidebar/Sidebar";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import "../styles.css";
import { useTranslation } from "react-i18next";

interface DashboardHeaderProps {
    onSearch: (searchValue: string) => void;
}

function DashboardHeader({ onSearch }: DashboardHeaderProps) {
    const { t } = useTranslation();
    const {
        commonStore,
        institutionStore: { selectedUserInstitution },
        tenantStore: { selectedTenant },
        authStore: { user }
    } = useStore();
    const { clientPagination } = commonStore;
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [showClear, setShowClear] = useState(false);

    const onClientAdd = () => {
        navigate("/users/create");
    };

    const handleSearch = (query: string) => {
        if (query === "") {
            setQuery("");
            setShowClear(false);
            return;
        }

        setQuery(query);
        setShowClear(true);
    }

    return (
        <Container className="client-dashboard-header" maxWidth="xl">
            <Sidebar />
            <Box className="header-container">
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Typography sx={{ fontWeight: '700', fontSize: '28px' }}>{t("SETTINGS_USERS_NAME")}</Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '13px', color: "#6b7483" }}>{(clientPagination && JSON.parse(clientPagination).TotalCount) ?? 0} {t("CLIENT_TOTAL")}</Typography>
                </Box>
                <Box className="search-bar-container">
                    <Paper
                        className="search-bar-style"
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            onSearch(query);
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, pl: 1, flex: 1, fontSize: "14px", width: "90%" }}
                            placeholder="Search by name or email"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={query}
                            endAdornment={showClear ?
                                <IconButton sx={{ color: "#808080", position: "absolute", right: 0 }} onClick={() => {
                                    handleSearch("");
                                    onSearch("");
                                }}>
                                    <CloseRoundedIcon />
                                </IconButton>
                                :
                                null
                            }
                        />
                        <IconButton sx={{ p: '10px' }} aria-label="search" title="Search" type="submit">
                            <SearchIcon color="secondary" />
                        </IconButton>
                    </Paper>
                </Box>
                <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    {(selectedUserInstitution?.roles!.some(role => role.includes("Field Coordinator")) || selectedTenant?.isAdmin || user?.isSuperAdmin) ?
                        <RoundButton className="media-button" variant="contained" sx={{ marginRight: '15px' }} title="Create" onClick={onClientAdd}>
                            {t("CLIENT_ADD")}
                        </RoundButton>
                        : ''
                    }
                </Box>
            </Box>
        </Container >
    );
}

export default observer(DashboardHeader);