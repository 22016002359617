import { TemplateContentDto, TemplateInstanceCreateDto, TemplateInstanceDto } from "../../data/models/notification";

/**
 * @summary Generates a copy of body content for each channel and project. The generation is based on isAllChannels and isAllProjects conditions. If any of the conditions are true,
 * the new content will be copied for those conditions, otherwise, default contents will be assigned. There should be always 4 contents generated on copy since it is required
 * to have per channel and project.
 * @param content The new content data with new html body
 * @param languageTag The language of template
 * @param institutionId Current institution
 * @param templateDefinitionId The Id of current content
 * @param contentList List of all 4 contents (the default contents will be copied as reference to this)
 * @returns
 */
export function templateContentForCreateResolver(content: TemplateContentDto, languageTag: string, institutionId: string, templateDefinitionId: string, contentList: TemplateContentDto[], hasAllChannels: boolean): TemplateInstanceCreateDto {
    const createContent = (commChannel: string, study: string, isBlocked: boolean, subject: string, body: string,) => ({
        id: '',
        subject: subject,
        body: handleSurveyLinkTags(body),
        commChannel,
        study,
        isBlocked
    });

    const getContents = () => {
        if (content.isAllChannels && content.isAllProjects) {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('PHONE', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('EMAIL', 'BRIGHT', content.isBlocked, content.subject, content.body),
                createContent('PHONE', 'BRIGHT', content.isBlocked, content.subject, content.body),
            ];
        } else if (content.isAllChannels && !content.isAllProjects) {
            return [
                createContent('EMAIL', content.study, content.isBlocked, content.subject, content.body),
                createContent('PHONE', content.study, content.isBlocked, content.subject, content.body),
                createContent(
                    'EMAIL',
                    content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT',
                    false,
                    getDefaultSubject(contentList, content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT', 'EMAIL') ?? content.subject,
                    getDefaultBody(contentList, content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT', 'EMAIL') ?? content.body
                ),
                createContent(
                    'PHONE',
                    content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT',
                    false,
                    getDefaultSubject(contentList, content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT', 'PHONE') ?? content.subject,
                    getDefaultBody(contentList, content.study === 'BRIGHT' ? 'SPIRIT' : 'BRIGHT', 'PHONE') ?? content.body
                ),
            ];
        } else if (!content.isAllChannels && content.isAllProjects && hasAllChannels) {
            return [
                createContent(content.commChannel.toUpperCase(), 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent(content.commChannel.toUpperCase(), 'BRIGHT', content.isBlocked, content.subject, content.body),
                createContent(
                    content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL',
                    'SPIRIT',
                    false,
                    getDefaultSubject(contentList, 'SPIRIT', content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL') ?? content.subject,
                    getDefaultBody(contentList, 'SPIRIT', content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL') ?? content.body
                ),
                createContent(
                    content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL',
                    'BRIGHT',
                    false,
                    getDefaultSubject(contentList, 'BRIGHT', content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL') ?? content.subject,
                    getDefaultBody(contentList, 'BRIGHT', content.commChannel.toUpperCase() === 'EMAIL' ? 'PHONE' : 'EMAIL') ?? content.body
                ),
            ];

        } else if (!content.isAllChannels && content.isAllProjects && !hasAllChannels) {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('EMAIL', 'BRIGHT', content.isBlocked, content.subject, content.body),
            ];
        }
        else if (!hasAllChannels) {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('EMAIL', 'BRIGHT', content.isBlocked, content.subject, content.body),
            ];
        } else {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('PHONE', 'SPIRIT', content.isBlocked, content.subject, content.body),
                createContent('EMAIL', 'BRIGHT', content.isBlocked, content.subject, content.body),
                createContent('PHONE', 'BRIGHT', content.isBlocked, content.subject, content.body),
            ];
        }
    };

    const getDefaultBody = (contents: TemplateContentDto[], project: string, commChannel: string): string | undefined => {
        const defaultContent = contents.find(c => c.commChannel === commChannel && c.study === project);
        return defaultContent?.body;
    };

    const getDefaultSubject = (contents: TemplateContentDto[], project: string, commChannel: string): string | undefined => {
        const defaultContent = contents.find(c => c.commChannel === commChannel && c.study === project);

        return defaultContent?.subject;
    };

    return {
        templateDefinitionId: templateDefinitionId,
        title: content.title as string,
        languageId: languageTag,
        institutionId: institutionId,
        contents: getContents(),
        hasAllChannels: hasAllChannels
    };
}


/**
 * @summary Generates a content for the current contentId based on selected channel and study options. This does not require to generate 4 contents. This can generate from
 * 1 up to 4 contents based on the selection.
 * @param content The content that will be updated
 * @param languageTag The language of template
 * @param institutionId Current institution
 * @param templateInstanceId The Id of the template instance
 * @returns 
 */
export function templateContentForUpdateResolver(content: TemplateContentDto, languageTag: string, institutionId: string, templateInstanceId: string, hasAllChannels: boolean): TemplateInstanceCreateDto {
    const baseContent = {
        subject: content.subject,
        body: handleSurveyLinkTags(content.body),
        id: content.id,
    };

    const createContent = (commChannel: string, study: string, isBlocked: boolean) => ({
        ...baseContent,
        commChannel,
        study,
        isBlocked,
    });

    const getContents = () => {
        if (content.isAllChannels && content.isAllProjects) {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked),
                createContent('PHONE', 'SPIRIT', content.isBlocked),
                createContent('EMAIL', 'BRIGHT', content.isBlocked),
                createContent('PHONE', 'BRIGHT', content.isBlocked),
            ];
        } else if (content.isAllChannels && !content.isAllProjects) {
            return [
                createContent('EMAIL', content.study, content.isBlocked),
                createContent('PHONE', content.study, content.isBlocked),
            ];
        } else if (!content.isAllChannels && content.isAllProjects && hasAllChannels) {
            return [
                createContent(content.commChannel.toUpperCase(), 'SPIRIT', content.isBlocked),
                createContent(content.commChannel.toUpperCase(), 'BRIGHT', content.isBlocked),
            ];
        } else if (!content.isAllChannels && content.isAllProjects && !hasAllChannels) {
            return [
                createContent('EMAIL', 'SPIRIT', content.isBlocked),
                createContent('EMAIL', 'BRIGHT', content.isBlocked),
            ];
        } else if (!hasAllChannels) {
            return [createContent('EMAIL', content.study, content.isBlocked)];
        } else {
            return [createContent(content.commChannel.toUpperCase(), content.study, content.isBlocked)];
        }
    };

    return {
        templateInstanceId: templateInstanceId,
        title: content.title as string,
        languageId: languageTag,
        institutionId: institutionId,
        contents: getContents(),
        hasAllChannels: hasAllChannels
    };
}

/**
 * @summary Generates a unique instance name upon copying a new template
 * @param baseName The title of the template
 * @param templates The list of existing template instances (to check whether the generated name exist in the list or not)
 * @returns 
 */
export function uniqueTemplateName(baseName: string, templates: TemplateInstanceDto[]): string {
    let names: string[] = templates.map(element => element.title);
    let newName = baseName;
    let counter = 0;

    const basePart = baseName.replace(/ default$/, '').replace(/ copy\d+$/, '');

    while (names.includes(newName)) {
        counter++;
        newName = `${basePart} copy${counter}`;
    }

    return newName;
}

export function handleSurveyLinkTags(bodyContent: string): string {
    // Regular expression to find {surveyLink} not within <a> tags
    let surveyLinkRegex = /(?<!<a[^>]*?>[^<]*)\{surveyLink\}(?![^<]*?<\/a>)/g;

    // Replace matches with the desired HTML
    bodyContent = bodyContent.replace(surveyLinkRegex, '<a href="{surveyLink}">{surveyLink}</a>');

    return bodyContent;
}
