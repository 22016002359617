import { Box, Skeleton } from "@mui/material";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StatusCard from "../../../../../components/StatusCard";
import { SessionSurveyStatus, SessionTimeline } from "../../../../../data/models/session";
import SessionEventDashboard from "../Events/SessionEventDashboard";
import "./styles.css";
import { useStore } from "../../../../../stores/store";

interface SessionStatusCardsProps {
    timeline: SessionTimeline | null;
}

function SessionStatusCards({ timeline }: SessionStatusCardsProps) {
    const { sessionStore } = useStore();
    const { session } = sessionStore;
    const { t } = useTranslation();
    const [completed, setCompleted] = useState<number | null>(null);

    useEffect(() => {
        if (timeline) {
            setCompleted(
                timeline.surveys.filter((s) => s.status === SessionSurveyStatus.COMPLETED).length
            );
        }
    }, [timeline]);

    return (
        <Box className="session-status-cards">
            <StatusCard
                header={t("SESSION_TIMELINE")}
                data={
                    session ? `${session.timeline}` : ""
                }
                className="session-status-card session-card"
            />
            <StatusCard
                header={t("CARD_COMPLETED")}
                data={
                    completed !== null ? (
                        `${completed}`
                    ) : (
                        <Skeleton sx={{ height: "48px", width: "55px" }} />
                    )
                }
                className="session-status-card session-card"
            />
            <StatusCard
                header={timeline?.timelineName ? timeline?.timelineName + ` ${t("CARD_EVENTS").toLowerCase()}` : t("CARD_EVENTS")}
                data={
                    <SessionEventDashboard selectedTimeline={timeline} />
                }
                style={{ marginRight: 0, width: "40% !important" }}
                className="session-status-card session-card"
                contentStyle={{ alignItems: "flex-start" }}
            />
        </Box>
    );
}

export default observer(SessionStatusCards);