import { Box, Chip, CircularProgress, Grid, IconButton, Tooltip, Typography, lighten } from "@mui/material";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';

import { useEffect, useState } from "react";
import { format } from "date-fns";

import { useStore } from "../../../stores/store";
import { ProcessClientsAddDto, ProcessDetails, ProcessStatus } from "../../../data/models/process";
import GenericTable from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import "./styles.css";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface ClientProcessTableProps {
    added: boolean;
    setAdded: (added: boolean) => void;
    showInfo: boolean;
    setShowInfo: (showInfo: boolean) => void;
}

function ClientProcessTable({ setAdded, added, showInfo, setShowInfo }: ClientProcessTableProps) {
    const { t } = useTranslation();
    const { processStore, clientStore, institutionStore } = useStore();
    const { getProcessList, addProcessClients, loading } = processStore;
    const { client } = clientStore;
    const { selectedUserInstitution } = institutionStore;
    const [processList, setProcessList] = useState<ProcessDetails[]>([]);
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedUserInstitution) {
                return;
            }
            const processes = await getProcessList(selectedUserInstitution.institutionId);
            setProcessList(processes);

            if (client) {
                const isAdded = processes.some(p => p.clientIds.includes(client.id));
                setAdded(isAdded);
            }
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, selectedUserInstitution]);

    const onProcessAdd = async (processId: string) => {
        if (added) return true;

        const processClient: ProcessClientsAddDto = {
            processId: processId,
            clientIds: [client?.id!],
            institutionId: selectedUserInstitution?.institutionId!,
        }

        await addProcessClients(processClient);
        toast.success(t("TOAST_PROCESS_CLIENT_ADD"));
        setAdded(true);
        setShowInfo(false);
    }

    const getStatusChip = (status: ProcessStatus): JSX.Element => {
        const statusText = status.toUpperCase();
        switch (statusText) {
            case ProcessStatus.PUBLISHED:
                return <Chip
                    icon={<FactCheckRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#2a9d8f !important" }} />}
                    label={<Typography fontSize={11} fontWeight={500}>{t(`SESSION_${statusText}`)}</Typography>}
                    sx={{ bgcolor: lighten("#2a9d8f", 0.8), height: "26px" }}
                />
            case ProcessStatus.DRAFT:
                return <Chip
                    icon={<DraftsRoundedIcon sx={{ fontSize: "16px", ml: "10px !important", color: "#a5a58d !important" }} />}
                    label={<Typography fontSize={11} fontWeight={500}>{t(`SESSION_${statusText}`)}</Typography>}
                    sx={{ bgcolor: lighten("#a5a58d", 0.8), height: "26px" }}
                />
            default:
                return <TableText>{t(`SESSION_${statusText}`)}</TableText>
        }
    }

    return (
        <Box>
            <Grid
                container
                columnSpacing={1}
                marginBottom="5px"
                padding="1rem 3rem"
            >
                <Grid item xs={12}>
                    <Typography fontWeight={500}>{t("PROCESSES_LABEL")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <GenericTable<ProcessDetails>
                        columns={[
                            {
                                header: <TableHeader fontSize={"13px !important"}>{t("GENERAL_NAME")}</TableHeader>,
                                key: "name",
                                render: (process) => <TableText>{process.name}</TableText>,
                                className: "process-cell"
                            },
                            {
                                header: <TableHeader fontSize={"13px !important"}>{t("CLIENT_PROCESS_STATUS")}</TableHeader>,
                                key: "status",
                                render: (process) => <Box>{getStatusChip(process.status)}</Box>,
                                className: "process-cell"
                            },
                            {
                                header: <TableHeader fontSize={"13px !important"}>{t("EVENT_START_TIME")}</TableHeader>,
                                key: "startTime",
                                render: (process) => <TableText>{format(new Date(process.startTime), "dd MMM, yyyy 'at' HH:mm")}</TableText>,
                                className: "process-cell"
                            },
                            {
                                header: <TableHeader fontSize={"13px !important"}>{t("EVENT_END_TIME")}</TableHeader>,
                                key: "endTime",
                                render: (process) => <TableText>{format(new Date(process.endTime), "dd MMM, yyyy 'at' HH:mm")}</TableText>,
                                className: "process-cell"
                            },
                            {
                                header: <></>,
                                key: "actions",
                                render: (process) => (
                                    <Tooltip
                                        title={added ? "Added to process" : "Add to process"}
                                        open={showTitle || showInfo}
                                        arrow={showInfo}
                                        onMouseEnter={() => setShowTitle(true)}
                                        onMouseLeave={() => setShowTitle(false)}
                                    >
                                        <span>
                                            <IconButton
                                                sx={{ p: 0, color: "#52b2ff" }}
                                                onClick={() => onProcessAdd(process.id)}
                                                disabled={loading || added}
                                            >
                                                {loading ?
                                                    <CircularProgress size={25} />
                                                    :
                                                    added ?
                                                        <HowToRegRoundedIcon sx={{ color: "#2a9d8f" }} />
                                                        :
                                                        <PersonAddAltRoundedIcon />}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ),
                                className: "process-cell"
                            },
                        ]}
                        rows={
                            processList || []
                        }
                        props={{
                            containerProps: {
                                style: {
                                    padding: 0
                                }
                            }
                        }}
                        rowKey="id"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(ClientProcessTable);