import {
    Box,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';

import { useEffect, useState } from "react";

import LoadingComponent from "./LoadingComponent";
import { useTranslation } from "react-i18next";

export type Column<T> = {
    header: JSX.Element;
    key: string;
    render: (row: T) => JSX.Element | string | null;
    className?: string;
    sort?: (a: T, b: T) => number;
    width?: number;
};

function EmptyBody() {
    const { t } = useTranslation();

    return (
        <Box sx={{
            position: "absolute",
            mt: "3.5rem",
            width: "95%",
            display: "flex",
            justifyContent: "center"
        }}>
            <Typography fontSize={13}>{t("GENERAL_NO_RESULT")}</Typography>
        </Box>
    );
}

type Props<T> = {
    columns: Column<T>[];
    rows: T[];
    rowKey: keyof T;
    props?: {
        tableBodyProps?: React.HTMLAttributes<HTMLTableSectionElement>;
        tableHeadProps?: React.HTMLAttributes<HTMLTableSectionElement>;
        tableProps?: React.HTMLAttributes<HTMLTableElement>;
        containerProps?: React.HTMLAttributes<HTMLDivElement>;
        tableContainerProps?: React.HTMLAttributes<HTMLDivElement>;
        rowProps?: React.HTMLAttributes<HTMLDivElement>;
        paginationProps?: React.HTMLAttributes<HTMLDivElement>;
    };
    pageSizeOptions?: number[];
    usePagination?: boolean;
    rowsPerPage?: number;
    rowCount?: number;
    loading?: boolean;
    toolbar?: React.ReactNode;
    onPaginationModelChange?: (page: number, pageSize: number) => void;
    onSortChange?: (key: string, type: SortType) => void;
};

export type SortType = "asc" | "desc";

function GenericTable<T>({
    columns,
    rows,
    rowKey,
    props,
    pageSizeOptions = [5, 10, 25],
    usePagination = false,
    rowCount,
    loading = false,
    toolbar,
    rowsPerPage = 10,
    onPaginationModelChange,
    onSortChange
}: Props<T>) {
    const { t } = useTranslation();
    const [data, setData] = useState(rows);
    const [page, setPage] = useState(0);
    const [rowPage, setRowPage] = useState(rowsPerPage);
    const [sortColumn, setSortColumn] = useState<{ key: string, type: SortType } | null>(null);
    const [focusedCell, setFocusedCell] = useState<string | null>(null);
    const [lastRowCount, setLastRowCount] = useState(rowCount ?? rows.length);
    const paginationLabels = {
        labelRowsPerPage: t("GENERAL_ROWS_PER_PAGE"),
        labelDisplayedRows: ({ from, to, count }: any) => `${from}-${to} ${t("GENERAL_OF")} ${count !== -1 ? count : `${t("GENERAL_MORE_THAN")} ${to}`}`
    };

    useEffect(() => {
        const currentLength = rowCount || rows.length
        if (lastRowCount > currentLength) {
            setPage(0);
            setLastRowCount(currentLength);
        }
        setLastRowCount(rowCount ?? rows.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    function handleChangePage(event: any, newpage: number) {
        setPage(newpage);
        if (onPaginationModelChange) {
            onPaginationModelChange(newpage, rowPage);
        }
    }

    function handleChangeRowsPerPage(event: any) {
        const newRowPage = parseInt(event.target.value, 10);
        setRowPage(newRowPage);
        setPage(0);
        if (onPaginationModelChange) {
            onPaginationModelChange(0, newRowPage);
        }
    }

    useEffect(() => {
        setData(rows);
    }, [rows])

    useEffect(() => {
        if (sortColumn) {
            const { key, type } = sortColumn;
            const sortedRows = [...rows].sort((a, b) => {
                const sortFn = columns.find(col => col.key === key)?.sort;
                if (sortFn) {
                    return sortFn(a, b) * (type === 'asc' ? 1 : -1);
                }
                return 0;
            });
            setData(sortedRows);
        } else {
            setData(rows);
        }
    }, [sortColumn, rows, columns])

    const getRows = () => {
        if (usePagination) {
            return data.slice(page * rowPage, page * rowPage + rowPage);
        }

        return data;
    };

    const calculateDefaultWidths = () => {
        const filteredColumns = columns.filter(c => !c.width && c.key !== "actions");
        const totalColumns = filteredColumns.length;

        const totalWidthLeft = 100 - columns.reduce((a, c) => (c.width ? a + c.width : a), 0);
        const defaultWidth = (totalWidthLeft / totalColumns).toFixed(3);

        const updatedColumns = columns.map(column => ({
            ...column,
            width: column.width ? `${column.width}%` : column.key === 'actions' ? '0%' : `${defaultWidth}%`,
        }));

        return updatedColumns;
    };

    const updatedColumns = columns.some(column => !column.width)
        ? calculateDefaultWidths()
        : columns;

    return (
        <Container
            sx={{
                marginLeft: "0 !important",
                marginRight: "0 !important",
                maxWidth: "1200px",
                "@media (min-width:1200px)": {
                    maxWidth: "100%",
                },
            }}
            {...props?.containerProps}
        >
            {toolbar}
            <TableContainer {...props?.tableContainerProps} sx={{ minHeight: rows.length === 0 ? 200 : "auto" }}>
                <Table stickyHeader {...props?.tableProps}>
                    <colgroup>
                        {updatedColumns.map((column) => (
                            <col key={column.key} style={{ width: column.width }} />
                        ))}
                    </colgroup>
                    <TableHead {...props?.tableHeadProps}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.key}
                                    sx={{ padding: "8px" }}
                                    className="generic-table-column"
                                >
                                    <Box display={"flex"} alignItems={"center"}
                                        onMouseEnter={() => setFocusedCell(column.key)}
                                        onMouseLeave={() => setFocusedCell(null)}
                                    >
                                        {column.header}
                                        {column.sort && <IconButton disableRipple sx={{ pl: "2px", opacity: sortColumn?.key === column.key ? 1 : focusedCell === column.key ? 0.6 : 0 }}
                                            onClick={() => {
                                                if (sortColumn?.key === column.key) {
                                                    if (sortColumn.type === "desc") {
                                                        setSortColumn(null);
                                                        if (onSortChange) {
                                                            onSortChange("", "asc");
                                                        }
                                                    } else {
                                                        setSortColumn({ key: column.key, type: sortColumn.type === "asc" ? "desc" : "asc" })
                                                        if (onSortChange) {
                                                            onSortChange(column.key, sortColumn.type === "asc" ? "desc" : "asc");
                                                        }
                                                    }
                                                } else {
                                                    setSortColumn({ key: column.key, type: "asc" })
                                                    if (onSortChange) {
                                                        onSortChange(column.key, "asc");
                                                    }
                                                }
                                            }}
                                        >
                                            {sortColumn?.key === column.key ?
                                                sortColumn.type === "asc" ?
                                                    <NorthRoundedIcon sx={{ fontSize: "14px" }} />
                                                    :
                                                    <SouthRoundedIcon sx={{ fontSize: "14px" }} />
                                                :
                                                <NorthRoundedIcon sx={{ fontSize: "14px", color: "#808080" }} />
                                            }
                                        </IconButton>}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        {...props?.tableBodyProps}
                        sx={{ position: "relative" }}
                    >
                        {rows.length === 0 ? (
                            <TableRow>
                                <TableCell sx={{ borderBottom: "none" }} colSpan={columns.length}>
                                    <EmptyBody />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {loading && <LoadingComponent gridProps={{ className: "table-spinning-loader" }} loaderProps={{ size: rows.length > 1 ? 40 : 25 }} />}
                                {getRows().map((row) => (
                                    <TableRow
                                        key={(row[rowKey] as string).toString()}
                                        {...props?.rowProps}
                                    >
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.key}
                                                sx={{ padding: "8px" }}
                                                className={`generic-table-column ${column.className}`}
                                            >
                                                {column.render(row)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {usePagination && (
                <TablePagination
                    {...props?.paginationProps}
                    rowsPerPageOptions={pageSizeOptions}
                    component="div"
                    count={rowCount ?? rows.length}
                    rowsPerPage={rowPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={paginationLabels.labelRowsPerPage}
                    labelDisplayedRows={paginationLabels.labelDisplayedRows}
                />
            )}
        </Container>
    );
}

export default GenericTable;
