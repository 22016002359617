import { Box, Typography } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import { CalendarEvent } from "../../../data/models/event";
import { differenceInMinutes, format } from "date-fns";
import { getFormattedMinutes } from "../../../utils/utils";
import "../styles.css";
import { generateColor } from "../../../utils/colorGenerator";
import { EventIcon } from "../Components/EventTypeIcons";
import { useTranslation } from "react-i18next";

interface EventDetailsProps {
    event: CalendarEvent;
    handleSelect?: (
        event: CalendarEvent,
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    showFullDate?: boolean;
    iconColor?: string;
    attendeeId?: string;
}

const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "9px 16px 9px 15px",
    "&:hover": {
        backgroundColor: "#eee",
        cursor: "pointer",
    },
};

const eventIconStyle = {
    height: "42px !important",
    width: "6px !important",
};

function EventDetails({
    event,
    handleSelect,
    showFullDate,
    iconColor,
    attendeeId,
}: EventDetailsProps) {
    const { t } = useTranslation();
    return (
        <Box
            sx={containerStyle}
            onClick={(e) => handleSelect && handleSelect(event, e)}
        >
            <EventIcon
                type={event.eventType as any}
                bgColor={iconColor ?? generateColor(event.viewId)}
                style={{ ...eventIconStyle }}
            />
            {showFullDate ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    marginLeft={1}
                    width="25%"
                >
                    <Typography variant="body2" component="p" fontSize={12}>
                        {format(event.start, "dd MMM, h:mm a")}
                    </Typography>
                    <Typography variant="body2" component="p" fontSize={12}>
                        {format(event.end, "dd MMM, h:mm a")}
                    </Typography>
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    marginLeft={1}
                    width="25%"
                >
                    <Typography variant="body2" component="p" fontSize={12}>
                        {format(event.start, "h:mm a")}
                    </Typography>
                    <Typography variant="body2" component="p" fontSize={12}>
                        {getFormattedMinutes(differenceInMinutes(event.end, event.start))}
                    </Typography>
                </Box>
            )}
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="75%"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="70%"
                >
                    <Typography
                        variant="h6"
                        component="h6"
                        fontSize={14}
                        width="70%"
                        height="23px"
                        overflow="hidden"
                    >
                        {event.title}
                    </Typography>
                    {attendeeId && (
                        <Box display="flex" width="100%" justifyContent="flex-end">
                            {event.attendeesStatus.find((a) => a.id === attendeeId)
                                ?.status === "ACCEPTED" ? (
                                <Typography
                                    fontSize="11px"
                                    sx={{ backgroundColor: "#2a9d8f", padding: "0 5px" }}
                                >
                                    {t("EVENT_ATTENDING")}
                                </Typography>
                            ) : (
                                <Typography
                                    fontSize="11px"
                                    sx={{ backgroundColor: "#e5e5e5", padding: "0 5px" }}
                                >
                                    {t("EVENT_NO_RESP")}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="85%"
                >
                    <Typography
                        variant="body2"
                        component="p"
                        fontSize={14}
                        width="70%"
                        height="23px"
                        overflow="hidden"
                    >
                        {event.description}
                    </Typography>
                    {event.recurrence !== "no-repeat" && event.id !== '' && (
                        <LoopIcon sx={{ fontSize: "18px", color: "#00000094" }} />
                    )}
                    {event.id === '' && (
                        <CorporateFareOutlinedIcon sx={{ fontSize: "18px", color: "#00000094" }} />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default EventDetails;
