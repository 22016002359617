import { Box, Typography } from "@mui/material";
import { CalendarEvent } from "../../../data/models/event";
import EventDetails from "../../Booking/Calendar/EventDetails";
import { useState } from "react";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useTranslation } from "react-i18next";

interface ClientEventsProps {
    events: CalendarEvent[];
    clientId: string;
}

function ClientEvents({ events, clientId }: ClientEventsProps) {
    const { t } = useTranslation();
    const [limit, setLimit] = useState(3);

    const handleLimit = () => {
        if (limit + 3 > events.length) {
            setLimit(events.length);
        } else {
            setLimit(limit + 3);
        }
    };

    return (
        <Box
            className="client-event-container"
            sx={{
                maxHeight: limit === events.length ? "230px" : "192px",
                minHeight: events.length === 0 ? "auto" : "230px",
            }}
        >
            <Typography>{t("CLIENT_EVENTS")}</Typography>
            {events.length === 0 &&
                <Typography variant="h6">{t("CLIENT_NO_EVENTS")}</Typography>
            }
            <Box className="client-event-item">
                {events.slice(0, limit).map((event, index) => (
                    <EventDetails
                        key={index}
                        event={event}
                        showFullDate={true}
                        iconColor="#457b9d"
                        attendeeId={clientId}
                    />
                ))}
                {limit < events.length && (
                    <RoundButton
                        sx={{ width: "100%", borderRadius: 0 }}
                        onClick={handleLimit}
                    >
                        {t("CLIENT_EVENTS_MORE")}
                    </RoundButton>
                )}
            </Box>
        </Box>
    );
}

export default ClientEvents;
