import { Chip, TextField, InputAdornment, Select, InputLabel, Autocomplete, Typography, Box, ListItem } from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { useState } from "react";
import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";
import { AttendeeDto } from "../../data/models/user";
import { isUUID } from "../../utils/utils";

interface ChipSelectProps extends FormInputProps {
    defaultValues?: AttendeeDto[];
    validation?: (value: string) => boolean;
    validationMessage?: string;
    // Use this to provide a list of options for Autocomplete component
    options?: AttendeeDto[];
}

function FormInputChipArea({ name, label, control, validation, validationMessage = 'Invalid input', defaultValues, options = [], helperText = " " }: ChipSelectProps) {
    const [values, setValues] = useState<AttendeeDto[]>(defaultValues || []);
    const [inputValue, setInputValue] = useState<string>('');
    const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);
    const [availableOptions, setAvailableOptions] = useState<AttendeeDto[]>(options.sort((a, b) => a.name.localeCompare(b.name)));
    const [duplicateError, setDuplicateError] = useState<string | null>(null);

    const handleInsert = (event: React.KeyboardEvent<HTMLDivElement>, onChange: Function) => {
        setDuplicateError(null);
        if (event.key === 'Enter') {
            event.preventDefault();
            const newValue = inputValue.trim();
            const opt = options.find((o) => o.email === newValue);

            const exists = values.some(v => v.email === newValue);
            if (exists) {
                setDuplicateError(newValue + " is already added");
                return;
            }
            if (newValue !== "" && !exists && (opt || (newValue && validation!(newValue)))) {
                const newAttendee = opt ? opt : { name: newValue, email: newValue };
                setValues([...values, newAttendee]);
                setInputValue('');
                onChange([...values, newValue]);
                const filteredOptions = availableOptions
                    .filter(option => option !== newAttendee)
                    .sort();
                setAvailableOptions(filteredOptions);
            } else {
                setIsInvalidInput(true);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setIsInvalidInput(false);
    };

    const handleBlur = (onChange: Function) => {
        const newValue = inputValue.trim();
        if (newValue === '') {
            return;
        }
        const opt = options.find((o) => (o.email + o.name).includes(newValue));
        if (opt || (newValue && validation!(newValue))) {
            const newAttendee = { name: newValue, email: newValue };
            setValues([...values, newAttendee]);
            setInputValue('');
            onChange([...values, newAttendee]);
        } else {
            setIsInvalidInput(true);
        }
    }

    const handleDelete = (valueToDelete: AttendeeDto, onChange: Function) => {
        setValues(values.filter(value => value !== valueToDelete));
        onChange(values.filter(value => value !== valueToDelete));
        if (options.some(v => v.email === valueToDelete.email && v.name === valueToDelete.name)) {
            const options = [...availableOptions, valueToDelete].sort((a, b) => a.name.localeCompare(b.name));
            setAvailableOptions(options);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={({ fieldState: { error }, field: { onChange } }) => (
                <>
                    <InputLabel sx={{ fontSize: '14px' }}>{label}</InputLabel>
                    <Autocomplete
                        multiple
                        clearIcon={null}
                        freeSolo
                        options={availableOptions}
                        value={values}
                        inputValue={inputValue}
                        getOptionLabel={(option) => {
                            const o = option as AttendeeDto;
                            return o.email + o.name;
                        }}
                        onChange={(event, newValue) => {
                            const val = newValue as AttendeeDto[];
                            if (val.length === 0) {
                                return;
                            }
                            const opt = options.find((o) => (o.email + o.name).includes(val[val.length - 1].email));

                            if (opt || validation!(val[val.length - 1].email)) {
                                setValues(val);
                                onChange(newValue);
                                if (newValue.length > 0) {
                                    const filteredOptions = availableOptions.filter(option => option !== newValue[newValue.length - 1]).sort();
                                    setAvailableOptions(filteredOptions);
                                    setIsInvalidInput(false);
                                }
                                setInputValue('');
                            }
                        }}
                        renderGroup={(params) => (
                            <Typography sx={{ fontWeight: 600, fontSize: '1.2rem' }} {...params} />

                        )}
                        renderOption={(props, option) => (
                            <ListItem className="attendee-list-item" {...props}>
                                <Typography fontSize="13px" mr={0.5}>{option.name}</Typography>
                                {option.email !== option.name &&
                                    <Typography fontSize="12px" mt="2px">{`(${isUUID(option.email) ? 'Guest' : option.email})`}</Typography>
                                }
                            </ListItem>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                error={!!error || isInvalidInput || duplicateError !== null}
                                helperText={duplicateError ? duplicateError : isInvalidInput ? validationMessage : (error ? error.message : helperText)}
                                name={`${name}.input`}
                                onBlur={() => handleBlur(onChange)}
                                onKeyDown={(e) => {
                                    if (e.key !== 'Backspace' && inputValue !== '') {
                                        // handleDelete(values[values.length - 1], onChange);
                                        handleInsert(e, onChange);
                                    }
                                }}
                                onChange={handleChange}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    alignItems: 'flex-start',
                                    maxWidth: '100%',
                                    '.MuiInputBase-root': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        rowGap: '5px',
                                        padding: '5px 7px',
                                        '& .MuiInputBase-multiline': {
                                            height: 'auto',
                                            minHeight: '48px',
                                        },
                                        'input': {
                                            minWidth: '20px',
                                            width: 'auto',
                                            flexGrow: 1,
                                            textOverflow: 'ellipsis',
                                            padding: '4px 7px',
                                            alignSelf: 'center',
                                            fontSize: '13px',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        padding: '4px 4px 4px 6px',
                                    },
                                    ...params.inputProps,
                                    'data-testid': `${name}.input`,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {values.map((value, index) => (
                                                <InputAdornment
                                                    key={`value-${index}`}
                                                    position="start"
                                                    sx={{ height: 'auto' }}
                                                >
                                                    <Chip
                                                        label={
                                                            <Box display="flex" alignItems="center">
                                                                <Typography fontSize="12px" mr={1}>{value.name}</Typography>
                                                                {value.email !== value.name &&
                                                                    <Typography fontSize="10px" mt="2px">{`(${isUUID(value.email) ? 'Guest' : value.email})`}</Typography>}
                                                            </Box>
                                                        }
                                                        title={isUUID(value.email) ? 'Guest' : value.email}
                                                        data-testid={`${name}-chip-${value}`}
                                                        onDelete={() => handleDelete(value, onChange)}
                                                        sx={{
                                                            padding: '4px',
                                                            maxWidth: '100%',
                                                            fontSize: '12px',
                                                        }}
                                                        deleteIcon={<HighlightOffOutlinedIcon />}
                                                    />
                                                </InputAdornment>
                                            ))}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Select name={name} value={values} multiple sx={{ display: 'none' }} />
                </>
            )}
        />
    );
}

export default FormInputChipArea;