import { observer } from "mobx-react-lite";
import { EventCreateDto, PMSessionEventToCreate, View } from "../../../data/models/event";
import { RoundButton } from "../../../_styles/StyledButtons";
import { CircularProgress, Typography } from "@mui/material";
import { t } from "i18next";
import { useStore } from "../../../stores/store";
import { useState } from "react";

interface Props {
    response: PMSessionEventToCreate[];
    newEvent: EventCreateDto;
}

const buttonStyles = {
    width: 'auto'
}

function CreateAllPMSessionsForm({ response, newEvent }: Props) {

    const { dialogStore: { dialog },
            modalStore: { closeModal, modal, closePmModal },
            bookingStore: { createPMSessionEvents, getUserEvents, getEvents, getRoomEvents, userEventsId},
            commonStore: {getCalendarViews} } = useStore();
    const [loading, setLoading] = useState(false);

    const formatDateAndTime = (date: Date): string => {
        const formattedDate = date.toLocaleDateString('en-GB');
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        return `${formattedDate}, ${formattedTime}`;
    }

    const formatTime = (date: Date): string => {
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        return `${formattedTime}`;
    }

    const getUnavailablePMs = (events: PMSessionEventToCreate[]): string => {
        const conflictingEvents = events.filter(event => event.hasConflict);

        const eventTypes = conflictingEvents.map(event => event.type).join(', ');

        return eventTypes;
    }

    const createPMSessions = async () => {
        const pmSessionEventsToCreate: EventCreateDto[] = [];

        setLoading(true);
        try {
            response.map(async event => {
                const pmEvent = { ...newEvent, subType: event.type, title: `Intervention / ${event.type}`, start: event.startDate, end: event.endDate };

                pmSessionEventsToCreate.push(pmEvent);
            });

            await createPMSessionEvents(pmSessionEventsToCreate);

            getCalendarViews().forEach(async (view) => {
                if (view.type === View.USER) {
                    if (view.id === userEventsId) {
                        await getUserEvents();
                    } else {
                        await getEvents(view.id);
                    }
                } else {
                    await getRoomEvents(view.id);
                }
            });

            modal.confirmClose = false;
            closeModal();
        } catch (error) {
            console.error("Error creating PM sessions:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <p>{t("SCHEDULED_PM_DESCRIPTION")}</p>
            <ul>
                {response.map(event => (
                    <li key={event.type}>
                        <b>{event.type}</b>: {formatDateAndTime(new Date(event.startDate))} - {formatTime(new Date(event.endDate))}
                    </li>
                ))}
            </ul>

            {
                response.filter(event => event.hasConflict).length !== 0
                ? <p>{t("UNAVAILABLE_PMS_DESCRIPTION").replace("{placeholder1}", getUnavailablePMs(response))}</p>
                : <p><i>{t("PM_CREATION_WARNING")}</i></p>
            }

            <div style={{ 
                 display: 'flex', 
                 justifyContent: 'flex-end', 
                 alignItems: 'center', // Ensures vertical alignment
                 gap: '8px'
                 }}>
                <RoundButton 
                    variant="text"
                    color="inherit"
                    title="Cancel"
                    onClick={() => { closePmModal() }}>
                        <Typography fontSize="13px">{t("GENERAL_CANCEL")}</Typography>
                </RoundButton>
                {
                    response.filter(event => event.hasConflict).length === 0 && (
                        <RoundButton 
                            variant="contained" 
                            color="primary" 
                            disabled={loading} 
                            onClick={createPMSessions} 
                            title="Create" 
                            data-testid="dialog-confirm"
                            // sx={buttonStyles}
                        >
                            {loading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <Typography fontSize="13px">{t("GENERAL_CREATE")}</Typography>
                            )}
                        </RoundButton>
                    )
                }
            </div>
        </div>
    );
}

export default observer(CreateAllPMSessionsForm);
