import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from "@mui/material";

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { FormInputText } from "../../../components/form/FormInputText";
import FormInputDate from "../../../components/form/FormInputDate";
import { RoundButton } from "../../../_styles/StyledButtons";
import FormInputSelect from "../../../components/form/FormInputSelect";
import {
    TaskDetails,
    Task,
    TaskCreateDto,
    CloudFile,
    TaskUpdateDto,
} from "../../../data/models/client";
import { useStore } from "../../../stores/store";
import FormInputFile from "../../../components/form/FormInputFile";
import CloudFileInfo from "../../../components/CloudFileInfo";
import { toDoListSchema } from "../../../_validators/schemas/toDoList.schema";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const priorityOptions = [
    { value: "LOW", label: "Low" },
    { value: "MEDIUM", label: "Medium" },
    { value: "HIGH", label: "High" },
];

interface ToDoFormProps {
    todo?: TaskDetails;
}

function ToDoForm({ todo }: ToDoFormProps) {
    const { t } = useTranslation();
    const defaultValues = {
        title: todo?.title ?? "",
        priority: todo?.priority ?? "MEDIUM",
        dueDate: dayjs(todo?.dueDate!).toDate() ?? null,
        notes: todo?.notes ?? "",
        completed: todo?.state ?? false,
    };
    const [cloudFiles, setCloudFiles] = useState<CloudFile[]>(todo?.files ?? []);
    const [files, setFiles] = useState<File[]>([]);
    const { clientStore, sidebarStore } = useStore();
    const { createTask, client, loading, getClientTasks, updateTask } =
        clientStore;
    const { closeSidebar } = sidebarStore;
    const { control, handleSubmit } = useForm<any>({
        resolver: yupResolver(toDoListSchema()),
        defaultValues: defaultValues,
    });
    const editMode = todo !== undefined;

    const onSubmit = async (data: any) => {
        const task: Task = {
            title: data.title,
            priority: data.priority,
            dueDate: data.dueDate.toISOString(),
            notes: data.notes,
        };

        if (editMode) {
            const existingFileIds = cloudFiles
                .filter((f) => !f.isDeleted)
                .map((f) => f.id!);
            const updatedTask: TaskUpdateDto = {
                id: todo.id,
                ...task,
                existingFiles: existingFileIds,
            };
            await updateTask(todo!.id!, updatedTask, files);
        } else {
            const newTask: TaskCreateDto = { ...task, userId: client?.id! };
            await createTask(newTask, files);
        }

        closeSidebar();
        await getClientTasks(client?.id!);
        toast.success(t("TOAST_TASK_SAVE"));
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mb: "4rem", display: "flex", flexDirection: "column" }}
        >
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <FormInputText name="title" control={control} label={`${t("TODO_TITLE")} *`} />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormInputSelect
                        name="priority"
                        control={control}
                        label={`${t("TODO_PRIORITY")} *`}
                        options={priorityOptions}
                        labelKey={"label"}
                        valueKey={"value"}
                    />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <FormInputDate name="dueDate" control={control} label={`${t("TODO_DATE")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        name="notes"
                        control={control}
                        label={t("GENERAL_NOTES")}
                        rows={3}
                    />
                </Grid>
                {editMode && (
                    <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                        <CloudFileInfo files={cloudFiles} setFiles={setCloudFiles} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormInputFile files={files} setFiles={setFiles} limit={5} />
                </Grid>
                {todo?.completedBy &&
                    <>
                        <Grid item xs={12} sx={{ margin: "1rem 0" }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontSize={"14px"} fontWeight={500}>
                                {t("TODO_COMP_DETAILS")}
                            </Typography>
                            <Box display={"flex"} alignItems={"center"} ml={"4px"}>
                                <Typography fontSize={"13px"} fontWeight={500}>
                                    {t("TODO_COMP_USER")}:&nbsp;
                                </Typography>
                                <Typography fontSize={"13px"}>
                                    {todo?.completedBy.firstName}{" "}
                                    {todo?.completedBy.lastName} ({todo.completedBy.email})
                                </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} ml={"4px"}>
                                <Typography fontSize={"13px"} fontWeight={500}>
                                    {t("TODO_DATE_TIME")}:&nbsp;
                                </Typography>
                                <Typography fontSize={"13px"}>
                                    {todo?.completedAt &&
                                        format(new Date(todo.completedAt), "dd MMMM, yyyy 'at' HH:mm")}
                                </Typography>
                            </Box>
                        </Grid>
                    </>}
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <RoundButton
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, alignSelf: "flex-end" }}
                        title="Submit"
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={25} />
                        ) : editMode ? (
                            <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                        ) : (
                            <Typography fontSize="13px">{t("GENERAL_CREATE")}</Typography>
                        )}
                    </RoundButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(ToDoForm);
