import { makeAutoObservable, runInAction } from "mobx";
import agent from "../utils/agent";
import { FollowUpType, SessionDeactivateRequest, SessionDetailsDto, SessionEmergencyRequest } from "../data/models/session";
import { store } from "./store";

export class SessionStore {
    session: SessionDetailsDto | null = null;
    loading = false;
    loadingStart = false;
    loadingTimelines = false;
    loadingCode = false;
    loadingEvents = false;
    loadingFileUpload = false;
    loadingBreak = false;

    constructor() {
        makeAutoObservable(this);
    }

    getSessions = async () => {
        store.loadingStore.startLoading(this.getSessions);
        try {
            const institution = store.institutionStore.selectedUserInstitution;
            if (institution) {
                const sessions = await agent.Session.list(institution.institutionId);
                return sessions;
            }
            store.loadingStore.stopLoading(this.getSessions);

            return null;
        } catch (error) {
            store.loadingStore.stopLoading(this.getSessions);
            throw error;
        }
    }

    getSession = async (sessionId: string, sessionToken?: string) => {
        store.loadingStore.startLoading(this.getSession);
        this.loading = true;
        try {
            const session = await agent.Session.getSession(sessionId, sessionToken);

            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getSession);

            return session;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSession);
            throw error;
        }
    }

    getAssessorSession = async (sessionId: string) => {
        store.loadingStore.startLoading(this.getAssessorSession);
        try {
            const session = await agent.Session.assessorSession(sessionId);
            store.loadingStore.stopLoading(this.getAssessorSession);

            return session;
        } catch (error) {
            store.loadingStore.stopLoading(this.getAssessorSession);
            throw error;
        }
    }

    loadSession = async (sessionId: string, sessionToken?: string) => {
        store.loadingStore.startLoading(this.loadSession);
        this.loading = true;
        try {
            const session = await this.getSession(sessionId, sessionToken);

            runInAction(() => {
                this.session = session;
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.loadSession);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.loadSession);
            throw error;
        }
    }

    loadAssessorSession = async (sessionId: string) => {
        store.loadingStore.startLoading(this.loadAssessorSession);
        try {
            const session = await this.getAssessorSession(sessionId);

            runInAction(() => this.session = session);
            store.loadingStore.stopLoading(this.loadAssessorSession);
        } catch (error) {
            store.loadingStore.stopLoading(this.loadAssessorSession);
            throw error;
        }
    }

    startUserSession = async (sessionId: string) => {
        store.loadingStore.startLoading(this.startUserSession);
        this.loadingStart = true;
        try {
            await agent.Session.startSession(sessionId);
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.startUserSession);

        } catch (error) {
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.startUserSession);
            throw error;
        }
    }

    setConsentionAgreement = async (sessionId: string, status: boolean) => {
        store.loadingStore.startLoading(this.setConsentionAgreement);
        try {
            await agent.Session.consentAgreement(sessionId, status);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.setConsentionAgreement);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.setConsentionAgreement);
            throw error;
        }
    }

    setTriageConsent = async (sessionId: string, status: boolean) => {
        store.loadingStore.startLoading(this.setTriageConsent);
        try {
            await agent.SessionOperations.triageConsent(sessionId, status);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.setTriageConsent);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.setTriageConsent);
            throw error;
        }
    }

    getSessionTimelines = async (sessionId: string) => {
        store.loadingStore.startLoading(this.getSessionTimelines);
        this.loadingTimelines = true;
        try {
            const timelines = await agent.Session.surveyList(sessionId);

            runInAction(() => this.loadingTimelines = false);
            store.loadingStore.stopLoading(this.getSessionTimelines);
            return timelines;
        } catch (error) {
            runInAction(() => this.loadingTimelines = false);
            store.loadingStore.stopLoading(this.getSessionTimelines);
            throw error;
        }
    }

    setCrisisAssessment = async (sessionId: string, accepted: boolean) => {
        store.loadingStore.startLoading(this.setCrisisAssessment);
        this.loadingCode = true;
        try {
            await agent.Session.crisisAssessment(sessionId, accepted);
            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.setCrisisAssessment);
        } catch (error) {
            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.setCrisisAssessment);
            throw error;
        }
    }

    excludeClient = async (sessionId: string, exclude: boolean) => {
        store.loadingStore.startLoading(this.excludeClient);
        try {
            await agent.Session.excludeClient(sessionId, exclude);
            store.loadingStore.stopLoading(this.excludeClient);
        } catch (error) {
            store.loadingStore.stopLoading(this.excludeClient);
            throw error;
        }
    }

    getSessionsByClient = async (clientId: string) => {
        store.loadingStore.startLoading(this.getSessionsByClient);
        this.loading = true;
        try {
            const sessions = await agent.Session.sessionsByClient(clientId);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSessionsByClient);
            return sessions;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSessionsByClient);
            throw error;
        }
    }

    getClientSessions = async () => {
        store.loadingStore.startLoading(this.getClientSessions);
        this.loading = true;
        try {
            const sessions = await agent.Session.clientSessions();

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getClientSessions);
            return sessions;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getClientSessions);
            throw error;
        }
    }

    verifySession = async (sessionId: string, code: string) => {
        store.loadingStore.startLoading(this.verifySession);
        this.loadingCode = true;
        try {
            const session = await agent.Session.verify(sessionId, code);

            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.verifySession);
            return session.token;
        } catch (error) {
            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.verifySession);
            throw error;
        }
    }

    resetSessionCode = async (sessionId: string) => {
        store.loadingStore.startLoading(this.resetSessionCode);
        this.loadingCode = true;
        try {
            await agent.Session.resetCode(sessionId);

            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.resetSessionCode);
        } catch (error) {
            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.resetSessionCode);
            throw error;
        }
    }

    getAttempts = async (sessionId: string) => {
        store.loadingStore.startLoading(this.getAttempts);
        this.loadingCode = true;
        try {
            const attempts = await agent.Session.attemptDetails(sessionId);

            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.getAttempts);
            return attempts;
        } catch (error) {
            runInAction(() => this.loadingCode = false);
            store.loadingStore.stopLoading(this.getAttempts);
            throw error;
        }
    }

    noShowUp = async (sessionId: string) => {
        store.loadingStore.startLoading(this.noShowUp);
        this.loadingStart = true;
        try {
            await agent.Session.noShow(sessionId);
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.noShowUp);
        } catch (error) {
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.noShowUp);
            throw error;
        }
    }

    setFollowUpType = async (sessionId: string, type: FollowUpType) => {
        store.loadingStore.startLoading(this.setFollowUpType);
        this.loadingStart = true;
        try {
            await agent.Session.followUpType(sessionId, type);
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.setFollowUpType);
        } catch (error) {
            runInAction(() => this.loadingStart = false);
            store.loadingStore.stopLoading(this.setFollowUpType);
            throw error;
        }
    }

    getTimelineEvents = async (sessionId: string, timelineId: string) => {
        store.loadingStore.startLoading(this.getTimelineEvents);
        this.loadingEvents = true;
        try {
            const timelineEvents = await agent.Session.events(sessionId, timelineId);

            runInAction(() => this.loadingEvents = false);
            store.loadingStore.stopLoading(this.getTimelineEvents);
            return timelineEvents;
        } catch (error) {
            runInAction(() => this.loadingEvents = false);
            store.loadingStore.stopLoading(this.getTimelineEvents);
            throw error;
        }
    }

    uploadConsent = async (sessionId: string, order: number, file: File) => {
        store.loadingStore.startLoading(this.uploadConsent);
        this.loadingFileUpload = true;
        try {
            const timelineEvents = await agent.Session.fileUpload(sessionId, order, file);

            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.uploadConsent);
            return timelineEvents;
        } catch (error) {
            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.uploadConsent);
            throw error;
        }
    }

    getConsentFiles = async (sessionId: string) => {
        store.loadingStore.startLoading(this.getConsentFiles);
        this.loadingFileUpload = true;
        try {
            const files = await agent.Session.fileList(sessionId);

            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.getConsentFiles);
            return files;
        } catch (error) {
            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.getConsentFiles);
            throw error;
        }
    }

    updateConsentFiles = async (sessionId: string, fileId: string, file: File) => {
        store.loadingStore.startLoading(this.updateConsentFiles);
        this.loadingFileUpload = true;
        try {
            await agent.Session.updateFile(sessionId, fileId, file);

            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.updateConsentFiles);
        } catch (error) {
            runInAction(() => this.loadingFileUpload = false);
            store.loadingStore.stopLoading(this.updateConsentFiles);
            throw error;
        }
    }

    getSessionBreak = async (sessionId: string, breakId: string) => {
        store.loadingStore.startLoading(this.getSessionBreak);
        this.loadingBreak = true;
        try {
            const sessionBreak = await agent.SessionBreak.get(sessionId, breakId);

            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.getSessionBreak);
            return sessionBreak;
        } catch (error) {
            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.getSessionBreak);
            throw error;
        }
    }

    startSessionBreak = async (sessionId: string, breakId: string, token?: string) => {
        store.loadingStore.startLoading(this.startSessionBreak);
        this.loadingBreak = true;
        try {
            await agent.SessionBreak.start(sessionId, breakId, token);
            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.startSessionBreak);
        } catch (error) {
            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.startSessionBreak);
            throw error;
        }
    }

    endSessionBreak = async (sessionId: string, breakId: string, token?: string) => {
        store.loadingStore.startLoading(this.endSessionBreak);
        this.loadingBreak = true;
        try {
            await agent.SessionBreak.end(sessionId, breakId, token);
            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.endSessionBreak);
        } catch (error) {
            runInAction(() => this.loadingBreak = false);
            store.loadingStore.stopLoading(this.endSessionBreak);
            throw error;
        }
    }

    confirmBaselineAllocation = async (sessionId: string) => {
        store.loadingStore.startLoading(this.confirmBaselineAllocation);
        try {
            await agent.Session.confirmBaseline(sessionId);
            store.loadingStore.stopLoading(this.confirmBaselineAllocation);
        } catch (error) {
            store.loadingStore.stopLoading(this.confirmBaselineAllocation);
            throw error;
        }
    }

    hasTimelineStarted = async (eventId: string) => {
        store.loadingStore.startLoading(this.hasTimelineStarted);
        try {
            const sessionStart = await agent.Session.hasStarted(eventId);
            store.loadingStore.stopLoading(this.hasTimelineStarted);

            return sessionStart.hasStarted;
        } catch (error) {
            store.loadingStore.stopLoading(this.hasTimelineStarted);
            throw error;
        }
    }

    sessionEmergency = async (sessionId: string, emergencyRequest: SessionEmergencyRequest) => {
        store.loadingStore.startLoading(this.sessionEmergency)
        try {
            agent.Session.sessionEmergency(sessionId, emergencyRequest)
            store.loadingStore.stopLoading(this.sessionEmergency);
        } catch (error) {

            store.loadingStore.stopLoading(this.sessionEmergency);
            throw error;
        }
    }

    deactivateSession = async (sessionId: string, deactivationRequest: SessionDeactivateRequest) => {
        store.loadingStore.startLoading(this.deactivateSession)
        try {
            await agent.Session.sessionDeactivate(sessionId, deactivationRequest)
            store.loadingStore.stopLoading(this.deactivateSession);
        } catch (error) {

            store.loadingStore.stopLoading(this.deactivateSession);
            throw error;
        }
    }

    resendEmail = async (sessionId: string) => {
        store.loadingStore.startLoading(this.deactivateSession)
        try {
            await agent.Session.resendEmail(sessionId)
            store.loadingStore.stopLoading(this.deactivateSession);
        } catch (error) {
            store.loadingStore.stopLoading(this.deactivateSession);
            throw error;
        }
    }
}